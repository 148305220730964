@import '../variables';

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.checkbox + label {
  position: relative;
  padding: 0 0 0 60px;
  cursor: pointer;
}

.checkbox + label:before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: 50px;
  height: 26px;
  border-radius: 13px;
  // background: rgba(239, 225, 249, 1);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  background: rgb(242, 232, 249);
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}

.checkbox + label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 2px;
  width: 22px;
  height: 22px;
  border-radius: 10px;
  background: rgb(181, 170, 208);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.checkbox:checked + label:before {
  background: rgb(210, 195, 244);
  background: rgb(216, 204, 245);
}

.checkbox:checked + label:after {
  left: 26px;
  background: rgb(150, 128, 205);
}
