.post {
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 14px;
  background-color: $background-posts;
  box-shadow: $shadow;
  font-family: Roboto;
  font-weight: 500;
  cursor: pointer;

  h3 {
    font-size: calcFluidFontSize(14, 16, 300, 1170);
    font-weight: 400;
  }

  &-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    @include onMobile {
      flex-direction: column;
      gap: 10px;
    }

    &__top {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }

      span {
        font-size: calcFluidFontSize(12, 14, 300, 1170);
        font-weight: 400;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    &__reading-time {
      // margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      & p {
        font-weight: 300;
        font-size: calcFluidFontSize(12, 14, 300, 1170);
        margin-bottom: 20px 0;
      }
    }
  }

  &-content {
    .post-photo img {
      display: block;
      width: 100%;
      max-height: 200px;
      object-fit: cover;
      margin-bottom: 20px;
      border-radius: 20px;
    }

    .post__description {
      font-weight: 300;
      font-size: calcFluidFontSize(12, 14, 300, 1170);
    }

    .post-content__body {
      font-weight: 300;
      font-size: calcFluidFontSize(12, 14, 300, 1170);
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      p {
        font-size: calcFluidFontSize(12, 14, 300, 1170);
        font-weight: 200;
      }
    }

    &__body {
      width: 100%;
    }
  }
}
