/* В файле suggestions.css */
.suggestions {
  .container {
    position: relative;
  }

  &__section {
    position: fixed;
    height: 373px;
    width: 235px;
    right: calc((100% - 1172px) / 2);
    background-color: $background_posts;
    padding: 24px;
    border-radius: 14px;
    box-shadow: $shadow;

    h2 {
      font-family: Itim, sans-serif;
      color: $main-color;
    }

    .suggestions__items {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      img {
        display: block;
        width: 48px;
        height: 48px;
      }

      .suggestion-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .user-info {
          display: flex;
          align-items: center;
          gap: 22px;
          font-size: 14px;
          font-family: Roboto;

          &__avatar {
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.user-page .suggestions__section {
  top: 490px;
}

.home-page .suggestions__section {
  top: 120px;
}
