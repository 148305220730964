
@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Light-BETA.otf');
    font-style: normal;
    font-weight: 300;

}

@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Medium.otf');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: Inter;
    src: url('../fonts/Inter-Bold.otf');
    font-style: normal;
    font-weight: 700;

}

@font-face {
    font-family: Itim;
    src: url('../fonts/Itim-Regular.ttf');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; /* Диапазон для латиницы */
}

@font-face {
    font-family: Itim;
    src: url('../fonts/ItimCyr.otf');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; /* Диапазон для кириллицы */
}
