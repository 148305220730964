.user-profile {
  background-color: white;
  font-family: Roboto;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.data-input:disabled {
  background-color: #f0f0f0;
  color: #6c757d;
  border: 1px solid #ced4da;
  cursor: not-allowed;
}

.user-form {
  width: 90%;
  max-width: 631px;
  margin: 120px auto 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f5f4f6;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 25px;
}

.data-input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.data-input::placeholder {
  color: #c1c2c5;
}

select#gender {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  appearance: none;
  background-color: white;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  color: #495057;
}

select#gender:disabled {
  background-color: #f0f0f0;
  color: #6c757d;
  border: 1px solid #ced4da;
  cursor: not-allowed;
}

button {
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #c2a2d8;
  color: white;
  border: none;
  border-radius: 10px;
  width: 100%;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #724881;
}

.pass__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  width: 100%;
  padding-left: 10px;
  margin: 0;
  border: none;
  background-color: #c2a2d8;
  cursor: pointer;
  color: white;
  letter-spacing: -0.5px;
}

.pass__btn img {
  height: 20px;
}

.pass__btn:disabled {
  cursor: not-allowed;
  color: #6c757d;
}

.label__block {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.upper-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #9a9a9a;
  padding-bottom: 20px;
}

.error-message {
  color: red;
}

.form-block {
  flex-direction: row;
  gap: 70px;
}

.data-input {
  width: 246px;
}

select#gender {
  width: 246px;
}

button {
  width: 119px;
}

.pass__btn {
  width: 246px;
}

@media screen and (max-width: 767px) {
  .user-form {
    margin: 120px auto 0 auto;
    overflow-y: auto;
  }

  .form-block {
    flex-direction: column;
    gap: 20px;
  }

  .data-input,
  select#gender,
  button,
  .pass__btn {
    width: 300px;
  }
}
