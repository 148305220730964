.edit-post {
  margin-top: 60px;
  font-family: Itim, sans-serif;
  font-weight: 400;

  &__section {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 14px;

    @include onLaptop {
      margin: 0px 20px 0px;
      font-size: 14px;
    }

    @include onMobile {
      margin: 0px 20px -90px;

      font-size: 12px;
    }

    h2 {
      margin-bottom: 20px;
    }

    .post-content {
      position: relative;

      .edit-button__box {
        position: absolute;
        top: 8px;
        right: 10px;
        display: flex;
        justify-content: flex-end;
      }

      &__top {
        .edit-tools {
          display: flex;
          gap: 8px;
          margin-top: 8px;
          margin-right: 8px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 10px;
            background-color: transparent;
            width: 40px;
            height: 40px;
            border: none;
            cursor: pointer;
            border-radius: 20px;

            .edit-bts {
              width: 24px;
            }

            &:active {
              color: $main-color;
            }

            &:disabled {
              cursor: not-allowed;
            }

            &:hover {
              color: $main-color;
            }
          }
        }
        .title-block {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
        }
      }

      img {
        display: block;
        width: 100%;
        max-height: 200px;
        object-fit: cover;
        margin-bottom: 20px;
      }
    }
  }
}
