.dropzone {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed #ddd;
    font-size: 16px;
    color: #ccc;
    margin-bottom: 25px;
    cursor: pointer;
    background-color: #ffffff;
    transition:
    border-color 0.2s ease-in-out,
    color 0.2s ease-in-out;
    overflow: hidden;
    height: 100px;
    width: 100%;
  }

  .uploaded_image p{
    text-align: center;
    margin-top: 40px;
  }
  .dropzone.active {
    border-color: #a1a5a9;
    color: #333;
  }

  .uploaded_image {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
  }

  .error_message {
    color: #ff0000; 
    font-size: 16px;
    text-align: center; 
    margin-top: 10px;
  }