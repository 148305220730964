.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #e9e338;

  .modal-content {
    background: white;
    background: #c2a2d8;
    padding: 20px;
    border-radius: 5px;
    z-index: 1001;
    position: relative;

    h2 {
      font-size: 14px;
      margin-bottom: 10px;
      font-family: Roboto;
    }

    .close {
      position: absolute;
      top: 0;
      right: 10px;
      padding: 10px;
      margin: 0;
      width: 40px;
      font-size: 14px;
      background-color: transparent;
    }

    .post-form {
      height: 100%;
      margin: 0;

      input,
      label {
        font-size: 12px;
      }

      textarea {
        font-size: 12px;
        height: auto;
      }

      .btn__sbmt {
        button {
          display: block;
          margin: 0;
          font-size: 12px;
          padding: 12px;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
