.header {
  background-color: $header-color;
  position: fixed;
  font-family: Roboto;
  font-size: 14px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;

  @include onMobile {
    padding: 8px 16px;
  }

  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 32px;

    @include onLaptop {
      margin: 0 20px;
    }

    @include onMobile {
      margin: 0 16px;
      flex-direction: column;
    }

    .header__logo {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include onMobile {
        width: 100%;
      }

      img {
        display: block;
      }

      .burger {
        display: none;
        font-size: 1.2rem;
        color: $main-color;
        cursor: pointer;
        @include onMobile {
          display: block;
        }
      }
    }

    .header__menu {
      @include onMobile {
        display: none;
      }
    }

    .header__nav {
      &-list {
        display: flex;
        gap: 24px;
        padding: 10px;

        @include onMobile {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 16px 0;
          font-size: 14px;
        }

        a {
          color: rgba(135, 116, 181, 1);
          padding: 10px;
          position: relative;
          text-decoration: none;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 0;
            height: 1px;
            background-color: rgb(129, 107, 181);
          }

          &:hover::before {
            width: 100%;
          }

          &::before {
            transition: width 0.35s;
          }
        }
      }
    }

    .header__user {
      position: relative;

      @include onMobile {
        font-size: 14px;
      }

      p {
        color: rgba(135, 116, 181, 1);
        cursor: pointer;
      }

      .dropdown-menu {
        display: none;
        position: absolute;
        top: 100%;
        left: -20px;
        width: 150px;
        background-color: #fff;
        border: 1px solid #ccc;
        padding: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 5;
        font-size: 14px;

        @include onMobile {
          font-size: 12px;
        }

        a {
          display: block;
          text-decoration: none;
          color: rgba(135, 116, 181, 1);
          padding: 8px;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &:hover .dropdown-menu {
        display: block;
      }
    }

    .open {
      @include onMobile {
        display: flex;
      }
    }
  }
}
