// @import '../variables';

.contact {
  background-color: rgba(245, 244, 246, 1);
  height: 100vh;
  font-family: Roboto;

  &-box {
    margin-top: 36px;
    padding-top: 60px;
    color: $font-color-text;

    .success-message {
      margin: 0 auto;
      text-align: center;
      font-weight: 300;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
      color: $main-color;
    }

    @include onMobile {
      margin: 30px 16px 0 16px;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .form-item {
        min-width: 350px;

        @include onMobile {
          min-width: 300px;
        }
      }

      .text-error {
        font-size: 10px;
        font-weight: 300;
        color: rgb(255, 0, 0);
      }

      input {
        margin-bottom: 15px;
        // width: 345px;
        width: 100%;
        height: 55px;
        padding: 8px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 15px;
        background-color: $input-color;
        border: none;
        transition:
          border-color 0.3s,
          background-color 0.3s;
      }

      input:focus {
        outline: none;
        border: 1px solid $secondary-color;
        background-color: #ffffff;
      }

      input::placeholder {
        font-size: 14px;
      }

      textarea {
        margin-bottom: 15px;
        max-width: 350px;
        min-width: 350px;
        height: 120px;
        padding: 10px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 15px;
        background-color: $input-color;
        border: none;
        font-family: Roboto;
        transition:
          border-color 0.3s,
          background-color 0.3s;

        @include onMobile {
          max-width: 310px;
          min-width: 310px;
        }
      }

      textarea::placeholder {
        font-size: 14px;
      }

      button {
        display: block;
        padding: 10px;
        font-size: 1rem;
        background-color: $secondary-color;
        color: #fff;
        border: none;
        cursor: pointer;
        width: 100%;
        height: 55px;
        border-radius: 15px;
        transition: background-color 0.5s;

        &:active {
          background-color: darken($secondary-color, 55%);
        }

        &:disabled {
          background-color: $tertiary-color;
          color: #6c6c6c;
          cursor: not-allowed;
        }
      }
    }
  }
}
