.edit-post__section {
  .post {
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 14px;
    // background-color: $font-color-sidebar;

    &-user {
      &__top {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px 0;

        img {
          display: block;
        }
      }
    }

    &-content {
      .post-photo img {
        display: block;
        // width: 100%;
        max-height: 300px;
        object-fit: contain;
        margin-bottom: 20px;
      }

      .post__description {
        margin-bottom: 20px;
      }

      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }
    }
  }
}
