button {
    padding: 10px;
    font-size: 1rem;
    background-color: $secondary-color;
    color: #fff;
    border: none;
    cursor: pointer;
    width: 100px;
    height: 50px;
    border-radius: 10px;
    transition: background-color 0.5s;

    &:active {
      background-color: darken($secondary-color, 55%);
    }

    &:disabled {
      background-color: $tertiary-color;
      color: #6c6c6c;
      cursor: not-allowed;
    }
}