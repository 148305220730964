@import '../variables';

.nav-link-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}

.registration {
  font-size: 16px;
  font-weight: 600;
  font-family: Roboto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to bottom right,
    #f3ddeb,
    #daadd0,
    #bf97c6,
    #8774b5
  );
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
    to bottom right,
    #f3ddeb,
    #daadd0,
    #bf97c6,
    #8774b5
  );

  .container {
    width: 430px;
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 20px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      & + p {
        margin-top: 20px;
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        color: $secondary-color;
      }

      h1 {
        font-size: 1.5rem;
        margin-bottom: 20px;
        text-align: center;
        color: $main-font-color;
      }

      .link {
        margin-bottom: 15px;
      }

      .text-error {
        font-size: 10px;
        font-weight: 300;
        // color: rgb(249, 6, 6);
        color: $secondary-color;
      }

      input {
        margin-bottom: 15px;
        width: 100%;
        // max-width: 345px;
        // width: 345px;
        height: 55px;
        padding: 8px;
        font-size: 1rem;
        border: 1px solid #ccc;
        border-radius: 15px;
        background-color: $input-color;
        border: none;
        transition:
          border-color 0.3s,
          background-color 0.3s;
      }

      input:focus {
        outline: none;
        border: 1px solid $secondary-color;
        background-color: #ffffff;
      }

      input::placeholder {
        font-size: 14px;
      }

      .input-password__wrapper {
        width: 100%;
        position: relative;
        .input-password {
          & + .password-toggle-icon {
            position: absolute;
            right: 0px;
            top: 27px;
            transform: translate(-50%, -50%);
            cursor: pointer;
            font-size: 1.2em;
          }
        }

        .password-eye {
          display: block;
          width: 24px;
          height: 24px;
        }
      }

      .registration-btn {
        margin-bottom: 10px;
        padding: 10px;
        font-size: 1rem;
        background-color: $secondary-color;
        color: #fff;
        border: none;
        cursor: pointer;
        width: 100%;
        // max-width: 345px;
        height: 55px;
        border-radius: 15px;
        transition: background-color 0.5s;

        &:active {
          background-color: darken($secondary-color, 55%);
        }

        &:disabled {
          background-color: $tertiary-color;
          color: #6c6c6c;
          cursor: not-allowed;
        }
      }

      select {
        width: 150px;
        background-color: #f4f4f4;
        border-radius: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        background-color: $input-color;
        border: none;
        padding: 5px;
        color: $secondary-font-color;

        &:focus {
          outline: none;
        }
      }

      .user-info {
        display: flex;
        gap: 10px;
        margin-top: 20px;

        p {
          font-family: Roboto;

          a {
            text-decoration: none;
            color: $main-color;
            padding: 10px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .registration {
    font-size: 14px;
    .container {
      padding: 15px;
    }

    .form input,
    .registration-btn {
      font-size: 0.9rem;
    }

    .input-password__wrapper .password-toggle-icon {
      top: 20px;
    }

    h1 {
      font-size: 1.2rem;
    }

    .text-error,
    & + p {
      font-size: 0.8rem;
    }

    .user-info p a {
      padding: 8px;
    }
  }
}
