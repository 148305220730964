.sidebar {
  z-index: 3;
}

.sidebar__section {
  span,
  div,
  h3,
  p {
    font-family: Roboto;
    font-size: 14px;
    // font-weight: normal;
  }

  margin: 80px 0px 0 0px;
  position: fixed;
  top: 39px;
  height: 373px;
  width: 235px;
  padding: 32px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .user__img {
      width: 130px;
      height: 130px;
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    padding: 15px;

    &-item {
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: center;
      padding: 10px;
      gap: 10px;
      text-decoration: none;
      color: $font-color-black;
      border-radius: 10px;
      border: 2px solid transparent;
      transition:
        border-color 0.3s ease,
        background-color 0.3s ease;
      font-weight: 400;

      &:hover {
        background-color: #efe1f9;
        border-color: #c6bcf7;
        text-decoration: none;
        cursor: pointer;
        font-weight: 700;
      }
      &.active {
        background-color: #efe1f9;
        border-color: #c6bcf7;
        text-decoration: none;
        cursor: pointer;
      }

      img {
        display: block;
        width: 20px;
        height: 20px;
        margin: 0 auto;
      }

      span {
        white-space: nowrap;
      }
      &:hover,
      &.active {
        background-color: #efe1f9;
        border-color: #c6bcf7;
        cursor: pointer;

        span {
          font-weight: 400; // Жирность текста для состояния :hover и .active
        }
      }
    }
  }
}
